.editAvatar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px;

    .avatarBox {
        position: relative;
        width: 96px;
        height: 96px;

        .avatar {
            border-radius: 96px;
            object-fit: cover;
        }

        .edit {
            display: flex;
            padding: 6px;
            bottom: 0;
            right: -10px;
            position: absolute;
            border-radius: 500px;
            background: #222;
        }
    }
}
