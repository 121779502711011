.userInfoCompletedContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    .userInfoCompleted {
        display: flex;
        flex-direction: column;
        padding: 0 16px;
        gap: 8px;
        overflow-y: auto;
        flex: 1;

        .titleArea {
            display: flex;
            flex-direction: column;

            .title {
                font-size: 24px;
                color: #d4d4d4;
                font-weight: 700;
                line-height: 36px;
            }

            .subTitle {
                color: #525252;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.28px;
            }
        }

        .formContainer {
            margin-top: 16px;

            .birthday {
                display: flex;
                flex-direction: column;
                margin-top: 16px;

                .birthdayTips {
                    color: #525252;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 20px;
                }

                .birthdayTipsAdult {
                    color: #d35454;
                }
            }

            .gender {
                display: flex;
                flex-direction: column;
                gap: 8px;
                color: #d4d4d4;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.28px;
                margin-top: 16px;

                .label {
                    color: #d4d4d4;
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 0.28px;
                }

                .genderOptions {
                    display: flex;
                    gap: 8px;

                    .genderOption {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 4px;
                        padding: 16px 40px;
                        background-color: #262626;
                        border: 1px solid #404040;
                        border-radius: 12px;

                        .genderText {
                            color: #d4d4d4;
                            font-size: 14px;
                            font-weight: 700;
                        }
                    }

                    .selectOption {
                        background-color: #404040;
                        border: 1px solid #d4d4d4;
                    }
                }

                .genderTips {
                    color: #525252;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 20px;
                }
            }
        }
    }

    .footer {
        position: absolute;
        width: 100%;
        bottom: 0;
        box-sizing: border-box;
        padding: 16px 16px 24px;
    }
}
