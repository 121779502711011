.validatePhone {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    padding: 24px 16px;
    background-color: #171717;
    z-index: 100;
    border-radius: 16px 16px 0 0;

    @media (width >= 513px) {
        width: 512px;
        margin: 0 auto;
    }

    .topIcon {
        display: flex;
        justify-content: space-between;
    }

    .closeIcon {
        margin-left: auto;
    }

    .turnstile {
        display: flex;
        flex-direction: column;
        align-items: center;

        .label {
            display: block;
            font-size: 14px;
            color: #d4d4d4;
            font-weight: 400;
            margin-bottom: 8px;
        }
    }

    .title {
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        color: #d4d4d4;
        margin: 32px 0;
    }

    .countryCodeSelect {
        height: 52px;
        font-weight: 400;
        color: #737373;
        margin-right: 8px;
        background: #0a0a0a;
        border: 1px solid #171717;
        border-radius: 8px;
        min-width: 88px;

        svg {
            color: #737373;
        }
    }

    .resend {
        flex-shrink: 0;
        margin-left: 8px;
    }

    .btn {
        margin-top: 24px;
    }
}

.disabled {
    background-color: #262626;
    color: #404040;
}
